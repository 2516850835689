import { Typography } from "@mui/material";
import Divider from "@mui/material/Divider";
import { useState, useContext, } from "react";
import Relatorio from "../../../components/common/Relatorio";
import { AuthContext } from "../../../contexts/auth";
import { IBetalabsRelatorio } from "../../../interfaces/ISeguros";
import "../../../styles/styles.css";

function RelatorioEmailsBetalabs() {
const {buscaRelatoriosEmailsBetalabs, exportaRelatorioEmailBetalabs } = useContext(AuthContext);
const [ficha, setFicha] = useState<IBetalabsRelatorio[]>([]);

    return (
        <>
            <Typography variant="h6">Relatório de Emails Betalabs</Typography>
            <Relatorio
                busca={buscaRelatoriosEmailsBetalabs}
                exporta={exportaRelatorioEmailBetalabs}
                ficha={ficha}
                setFicha={setFicha}
                relatorio={"EmailBetalabs"}
            />
            <Divider variant="middle" />
        </>
    );
}

export default RelatorioEmailsBetalabs;
