import { Box, Checkbox, FormControl, FormControlLabel, Grid, InputLabel, MenuItem, Select, SelectChangeEvent, TextField } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../contexts/auth";

interface IFilterFormOnboardingProps {
    handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    handleChangeDate: (event: React.ChangeEvent<HTMLInputElement>) => void;
    handleSelectChange: (event: SelectChangeEvent<HTMLSelectElement>) => void
    formData: any;
}

const FilterFormOnboarding: React.FC<IFilterFormOnboardingProps> = ({ handleChange, handleChangeDate, handleSelectChange, formData }) => {
    const { listaTemplatesEmailsBetalabs } = useContext(AuthContext);

    const [templatesName, setTemplatesName] = useState<string[]>([]);
    const [isEmailOpened, setIsEmailOpened] = useState<boolean>(false);

    useEffect(() => {
        const fetchTemplates = async () => {
            const templates = await listaTemplatesEmailsBetalabs();

            if (templates) {
                setTemplatesName(templates);
            }
        };

        fetchTemplates();
    }, []);

    const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, checked } = event.target;
        
        setIsEmailOpened(checked);

        handleChange({
            target: {
                name,
                value: checked.toString(),
            },
        } as React.ChangeEvent<HTMLInputElement>);
    };
    
    const formatTemplateName = (str: string) => {        
        return str
            .replace(/([A-Z])/g, ' $1') // Adiciona espaço antes de cada letra maiúscula
            .trim() // Remove espaços desnecessários
            .replace(/\b\w/g, (char) => char.toUpperCase()) // Coloca a primeira letra em maiúscula
    };

    const defaultDataDe = formData.dataInicial ? formData.dataInicial.toISOString().substring(0, 10) : "";
    const defaultDataAte = formData.dataFinal ? formData.dataFinal.toISOString().substring(0, 10) : "";
    const campoObrigatorio = (formData.dataInicial && formData.dataFinal);

    const defaultEmail = formData.Email || "";
    const defaultCodigoImovel = formData.CodCondom || "";
    const defaultTemplate = formData.TemplateName || "";

    return (
        <Grid container spacing={2}>
            <Grid item xs={9}>
                <Grid container spacing={2}>
                    <Grid item xs={4}>
                        <FormControl sx={{ width: '100%' }}>
                            <TextField
                                label="Email"
                                type="text"
                                name="ClientEmail"
                                value={defaultEmail}
                                onChange={handleChange}
                                fullWidth
                            />
                        </FormControl>
                    </Grid>

                    <Grid item xs={4}>
                        <FormControl sx={{ width: '100%' }}>
                            <TextField
                                label="Código condomínio"
                                type="text"
                                name="CodCondom"
                                value={defaultCodigoImovel}
                                onChange={handleChange}
                                fullWidth
                            />
                        </FormControl>
                    </Grid>

                    <Grid item xs={4}>
                        <FormControl sx={{ width: '100%' }}>
                            <InputLabel id="template-name-label">Tipo</InputLabel>
                            <Select
                                labelId="template-name-label"
                                label="Template"
                                name="TemplateName"
                                value={defaultTemplate}
                                onChange={handleSelectChange}
                                fullWidth
                            >
                                <MenuItem value="">
                                    <em>Limpar</em>
                                </MenuItem>
                                {templatesName.map((template) => (
                                    <MenuItem key={template} value={template}>
                                        {formatTemplateName(template)}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>

                </Grid>
            </Grid>

            <Grid item xs={3}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <FormControl sx={{ width: '100%' }}>
                            <TextField
                                label="De:"
                                type="date"
                                name="dataInicial"
                                value={defaultDataDe}
                                onChange={handleChangeDate}
                                fullWidth
                                required={campoObrigatorio}
                                InputLabelProps={{ shrink: true }}
                            />
                        </FormControl>
                    </Grid>

                    <Grid item xs={12}>
                        <FormControl sx={{ width: '100%' }}>
                            <TextField
                                label="Até:"
                                type="date"
                                name="dataFinal"
                                value={defaultDataAte}
                                onChange={handleChangeDate}
                                fullWidth
                                required={campoObrigatorio}
                                InputLabelProps={{ shrink: true }}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={isEmailOpened}
                            name="OpenedEmail"
                            onChange={handleCheckboxChange}
                        />
                    }
                    label="Teve Abertura?"
                />
            </Grid>
        </Grid>
    );
};

export default FilterFormOnboarding;
